const toJson = (type: string, jsonld: { id?: string; schema: any }): { __html: string } => {
  const { id = undefined, schema } = jsonld;
  let updated;

  if (Array.isArray(schema)) {
    updated = schema.reduce((init: string, item) => {
      return (
        init +
        JSON.stringify({
          ...item,
        })
      );
    }, '');
  } else {
    updated = {
      ...(id ? { '@id': jsonld.id } : {}),
      ...schema,
    };
    if (!Array.isArray(updated)) {
      delete updated.id;
    }
    updated = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': type,
      ...updated,
    });
  }

  return {
    __html: updated,
  };
};

export interface JsonLdProps {
  type: string;
  scriptId?: string;
  scriptKey: string;
  keyOverride?: string; // keyOverride added here.
  schema: any; // Explicitly mentioning schema for clarity.
}

function JsonLd({ type, keyOverride, scriptKey, scriptId = undefined, schema }: JsonLdProps) {
  return (
    <script
      type="application/ld+json"
      id={scriptId}
      dangerouslySetInnerHTML={toJson(type, { schema })}
      key={`jsonld-${scriptKey}${keyOverride ? `-${keyOverride}` : ''}`}
    />
  );
}

export default JsonLd;
