'use client';

import { useState } from 'react';
import { AngledArrowDownIcon, AngledArrowUpIcon } from '../../ui/icons/icons';

const ShowMoreBtn = ({ onShowState, showLessText, showMoreText, ...rest }: any) => {
  const [showState, setShowState] = useState(false);
  const showMoreHandler = () => {
    setShowState((latestShowState) => !latestShowState);
    onShowState(showState);
  };

  return (
    <button
      onClick={showMoreHandler}
      className="mt-9 flex w-full justify-center gap-2 text-sm font-semibold text-purple500 hover:cursor-pointer"
      {...rest}
    >
      {showState ? showLessText : showMoreText}
      {showState ? (
        <AngledArrowUpIcon className="text-xl text-purple500" />
      ) : (
        <AngledArrowDownIcon className="text-xl text-purple500" />
      )}
    </button>
  );
};

export default ShowMoreBtn;
