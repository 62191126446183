import { getCustomerInfoActions } from '../features/common/native-card/actions';
import { loadAofSdkScript } from './dijikolayScriptLoader';

declare global {
  var AofSdk: any;
}

let aofSdkInstance: any = null;

export const dijikolayIntegration = async () => {
  try {
    await loadAofSdkScript();

    if (typeof AofSdk === 'undefined') {
      throw new Error('AofSdk is not defined after script load.');
    }

    const isProd = process.env.NEXT_PUBLIC_APP_MODE === 'PROD';
    const sdkOptions = {
      environment: isProd ? 'PRODUCTION' : 'TEST',
      loginPopup: false,
    };
    const sdkOptCustomerInfo = await getCustomerInfoActions();

    const getCustomerInfo = () => {
      return {
        platformId: sdkOptCustomerInfo?.platformId ?? '',
        platformName: sdkOptCustomerInfo?.platformName ?? '',
        customerNoForPlatform: '',
        customerTitle: sdkOptCustomerInfo?.customerTitle ?? '',
        isLegalPerson: sdkOptCustomerInfo?.isLegalPerson ?? '',
        nationalId: '',
        taxpayerId: sdkOptCustomerInfo?.taxpayerId ?? '',
        hashedInfo: sdkOptCustomerInfo?.hashedInfo ?? '',
      };
    };

    const startOptions = { getCustomerInfo, mountDivId: 'plateau_ui_placeholder' };

    if (!aofSdkInstance) {
      aofSdkInstance = new AofSdk(sdkOptions);
    }

    const aofSdk = aofSdkInstance;

    aofSdk.load(async (isReady: any, requireStart: any) => {
      if (isReady) {
        aofSdk.start(startOptions);
      }
    });
  } catch (error) {
    console.error('Error during dijikolayIntegration:', error);
  }
};
