import { ResponseWidget, ResponseWidgetDetail } from '@/src/interfaces/data-contracts';
import JsonLd from '@/src/utils/helpers/jsonld';

const charMap: Record<string, string> = {
  '"': "'",
  '\n': '',
  '\r': '',
  '\t': '',
};

interface FAQSchemaProps {
  faqData: ResponseWidget;
}

const FAQSchema: React.FC<FAQSchemaProps> = ({ faqData }) => {
  const details = (faqData.details as ResponseWidgetDetail[]) ?? [];

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: details.map((item) => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.description?.replace(/"|\n|\r|\t/g, (matched) => charMap[matched] ?? matched),
      },
    })),
  };

  return <JsonLd type="FAQPage" scriptKey="FAQPage" schema={schema} />;
};

export default FAQSchema;
