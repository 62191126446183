type Props = {
  displayState: boolean;
};

export const AccordionTabIcon: React.FC<Props> = (props) => {
  return (
    <span>
      {props.displayState ? (
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 7.5L8 1.25L14.25 7.5"
            stroke="#5D5BEA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.25 1.5L8 7.75L1.75 1.5"
            stroke="#A1A6AF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  );
};
