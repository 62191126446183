export const loadAofSdkScript = () => {
  return new Promise<void>((resolve, reject) => {
    if (typeof window === 'undefined') {
      return reject(new Error("Window is undefined, won't load aof script."));
    }

    const isProd = process.env.NEXT_PUBLIC_APP_MODE === 'PROD';
    const scriptSrc = isProd
      ? 'https://aof-cdn.epaykolay.com/aofSdk.js'
      : 'https://aof-cdn.test.epaykolay.com/aofSdk.js';

    if (document.querySelector(`script[src="${scriptSrc}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    script.onload = () => {
      // pushing resolve to macro task queue to ensure script is loaded, needs testing
      setTimeout(() => {
        resolve();
      }, 0);
    };
    script.onerror = () => reject(new Error('Failed to load AofSdk script'));
    document.head.appendChild(script);
  });
};
