'use client';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { ResponseNativeCard } from '@/src/interfaces/data-contracts';
import { DeviceContext } from '@/src/store/contexts/device-context';
import NativeCardModal from './native-card-modal';
import NativeCardTooltip from '../../ui/tooltip/native-card-tooltip';
import { RelLink } from '../../ui/rel-link';
import { BlogModulesActions } from './actions';
import SegmentHub from '@/src/app/api/segmenthub/events';

const getIntegrationTypeFromLink = (link: string | undefined): string | null => {
  if (!link) return null;

  if (link.startsWith('$dijikolay')) {
    return 'dijikolay';
  }
  return null;
};

type Props = {
  item: ResponseNativeCard;
};

export const NativeCard: React.FC<Props> = ({ item }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [integrationData, setIntegrationData] = useState<any>(null);
  const [isLoadingIntegrationData, setIsLoadingIntegrationData] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();

  const { isMobile } = useContext(DeviceContext);
  const searchParams = useSearchParams();

  const rsParam = searchParams?.get('rs');

  const nativeCardPictures = item.nativeCardPictures;

  const defaultImageSrc = '';
  const imageSrc = isMobile
    ? nativeCardPictures?.mobileImagePath || nativeCardPictures?.tabletImagePath || defaultImageSrc
    : nativeCardPictures?.desktopImagePath || defaultImageSrc;

  const altTitle = nativeCardPictures?.altTitle || '';
  const imageAlt = `${altTitle} Kobi Kredisi`;

  const imageProps = {
    src: imageSrc,
    alt: imageAlt,
    width: 1120,
    height: 146,
    className: 'shadow-faq',
  };

  const integrationType = getIntegrationTypeFromLink(item.link ?? '');
  const hasIntegration = integrationType !== null;

  const hasLinkAndTarget = Boolean(item.link && item.target);
  const showTooltip = Boolean(item.isActiveInformationTooltip);

  const imageElement = (
    <Image
      {...imageProps}
      loading="eager"
      priority
      decoding="async"
      style={{ objectFit: 'contain' }}
      data-testid="nativeCardImage"
    />
  );

  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!item.link || !item.id) return;
    await SegmentHub.purchaseNative(item.id);

    if (item.target === '_blank')
      setTimeout(() => {
        // Thanks safari
        window.open(item.link!);
      }, 0);
    else router.push(item.link);
  };

  const handleOpenPopup = useCallback(async () => {
    setIsPopupOpen(true);

    if (hasIntegration && item.link) {
      const idMatch = item.link.match(/\d+/);
      const integrationId = idMatch ? Number(idMatch[0]) : null;

      if (integrationId !== null && integrationId >= 0) {
        setIsLoadingIntegrationData(true);
        setError(null);
        try {
          const data = await BlogModulesActions(integrationId);
          setIntegrationData(data);
        } catch (err) {
          console.error('Error fetching integration data:', err);
          setError('Failed to load integration data.');
        } finally {
          setIsLoadingIntegrationData(false);
        }
      } else {
        console.warn('Invalid integration ID');
        setError('Invalid integration ID.');
        setIsLoadingIntegrationData(false);
      }
    }
  }, [hasIntegration, item.link]);

  const handleClosePopup = useCallback(() => {
    setIsPopupOpen(false);
    setIntegrationData(null);
    setError(null);
  }, []);

  // Automatically open popup if rsParam is '1' and integrationType matches
  // this 'rs' param is for dijikolay only, this should be refactored to support
  // future integrations with other brands
  useEffect(() => {
    if (rsParam === '1' && hasIntegration) {
      setIsPopupOpen(true);
    }
  }, [rsParam, hasIntegration]);

  let content;

  if (hasLinkAndTarget) {
    if (hasIntegration) {
      content = (
        <>
          <button
            onClick={handleOpenPopup}
            className={classNames('h-auto w-full shadow-faq')}
            data-testid="nativeCardButton"
          >
            {imageElement}
          </button>
          {isPopupOpen && (
            <NativeCardModal
              isPopupOpen={isPopupOpen}
              item={integrationData}
              isLoading={isLoadingIntegrationData}
              error={error}
              closePopup={handleClosePopup}
              integrationType={integrationType}
              rsParam={rsParam}
              data-testid="nativeCardModal"
            />
          )}
        </>
      );
    } else if (item.link && item.target) {
      content = (
        <RelLink
          onClick={handleClick}
          href={item.link}
          target={item.target}
          className="h-auto w-full shadow-faq"
          data-testid="nativeCardLink"
        >
          {imageElement}
        </RelLink>
      );
    } else {
      // image only
      content = imageElement;
    }
  } else {
    content = imageElement;
  }

  return (
    <>
      {content}
      {showTooltip && (
        <NativeCardTooltip
          isActiveInfoTooltip={item.isActiveInformationTooltip ?? false}
          TooltipText={item.informationTooltipText ?? ''}
          isDesktop={!isMobile}
          tooltipTitle={item.informationTooltipText ?? ''}
          TooltipLeftToRight={item.informationTooltipLeftToRight ?? 0}
          TooltipTopToDown={item.informationTooltipTopToDown ?? 0}
        />
      )}
    </>
  );
};

export default NativeCard;
