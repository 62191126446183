import { validateUrl } from '@/src/utils/helpers/validate-url';
import Image from 'next/image';

const validImageExtensions = /\.(gif|jpe?g|tiff?|png|webp|svg|bmp)$/i;
const ImageWithFallback = (props: { [x: string]: any; src: any; alt: any }) => {
  const { src, alt, ...rest } = props;
  const broken = 'https://via.placeholder.com/803x425.png?text=Görsel%20Yükleniyor...';
  const ckImg = validImageExtensions.test(src) && validateUrl(src) ? src : broken;
  return (
    <Image
      alt={alt}
      quality={80}
      src={ckImg}
      key={ckImg}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      decoding="async"
      loading={rest.loading ? 'lazy' : rest.loading}
      {...rest}
    />
  );
};

export default ImageWithFallback;
